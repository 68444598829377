"use client";
import React from "react";
import "./Hero.css";
import { Link } from "react-scroll";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="hero" id="home">
      <img
        src={require("./images/flag3.jpg")}
        className="w-full h-full object-cover
         absolute  "
      />
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="content"
      >
        <div className="innerContent">
          <h1>Expand your healthcare business beyond boundaries</h1>
          <p>
            <span>Oz HealthBridge</span> collaborates with partners in
            pharmaceutical medicine , medical devices and consumables to access
            and expand business in Australia and Gulf region.{" "}
          </p>
        </div>

        <Link smooth={true} offset={-100} duration={700} to="contact">
          <motion.div
            whileHover={{
              boxShadow: " rgba(250, 250, 250, 0.19) 0px 0px 10px 10px",
              scale: 1.1,
            }}
            className="button"
            to="contact"
            smooth={true}
            duration={700}
            offset={-100}
          >
            Contact us
          </motion.div>
        </Link>
      </motion.div>
    </div>
  );
};

export default Hero;
