import { useState } from "react";
import FormInput from "./FormInput";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FadeLoader } from "react-spinners";
import { motion } from "framer-motion";

// const myFont = localFont({
//   src: "./fonts/YoungSerif-Regular.ttf",
// });
// const emailFont = localFont({
//   src: "./fonts/Pacifico-Regular.ttf",
// });
const Form = () => {
  const [values, setValues] = useState({
    username: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "Full Name",
      errorMessage:
        "Full Name should be 3-16 characters and shouldn't include any special character!",
      label: "Full Name",
      pattern: "^[A-Za-z ]{3,16}",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      pattern: `^[a-z0-9._%+-]+@[a-z0-9.-]+[\.a-z]{2,4}$`,
      required: true,
    },
    {
      id: 3,
      name: "subject",
      type: "text",
      placeholder: "Subject",
      label: "subject",
      errorMessage: "Please tell us what this message about!",
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "textArea",
      placeholder: "message",
      errorMessage: "message should be 8-300 characters !",
      label: "message",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,300}$`,
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        // process.env.REACT_APP_SERVICE_ID,
        "service_tfk7fhe",
        "template_iwk203x",
        values,
        // process.env.REACT_APP_KEY
        "7P6x6gXlz3Iv4mDDo"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            setLoading(false);

            toast.success(
              "Thank you for your message, we will respond as soon as possible",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    const reset = () => {
      setValues({
        username: "",
        email: "",
        subject: "",
        message: "",
      });
    };
    setTimeout(reset, 7000);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center spinner">
        <FadeLoader color="#3f4540" />
      </div>
    );
  }

  return (
    <div id="form-container">
      <form onSubmit={handleSubmit} id="form">
        <motion.div
          initial={{ opacity: 0.5, x: "-50%" }}
          transition={{ type: "spring", stiffness: 100, damping: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaEnvelopeOpenText style={{ fontSize: "30px" }} />{" "}
          <h2>Contact Us</h2>
        </motion.div>
        <span className="line"></span>

        <p
          style={{
            textAlign: "justify",
            marginBottom: "20px",
            marginTop: "50px",
            lineHeight: "30px",
          }}
        >
          Let <span>OZ HealthBridge</span> be your trusted partner in expanding
          your reach and enhancing healthcare delivery.
        </p>
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "20px",
            lineHeight: "30px",
          }}
        >
          Please send us an email on
          <span
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaEnvelopeOpenText />
            <a href="mailto: info@ozhealthbridge.com.au">
              info@ozhealthbridge.com.au
            </a>
          </span>
          or, simply fill out the form below and we will contact you back as
          soon as possible
        </p>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className="button">Submit</button>
      </form>
    </div>
  );
};

export default Form;
