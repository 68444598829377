"use client";
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "./images/logo6.png";

import "./Navbar.css";
import { Link } from "react-scroll";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);

  const headerClass = () => {
    if (window.scrollY > 90) {
      setHeaderColor(true);
    } else {
      setHeaderColor(false);
    }
  };

  window.addEventListener("scroll", headerClass);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <div className={headerColor ? "header nav-bg" : "header"}>
      <nav className="navbar">
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="home"
              onClick={closeMenu}
              smooth={true}
              offset={0}
              duration={700}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="about"
              onClick={closeMenu}
              smooth={true}
              offset={10}
              duration={700}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="services"
              onClick={closeMenu}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="contact"
              onClick={closeMenu}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Contact Us
            </Link>
          </li>
        </ul>
        <Link
          to="home"
          className="logo"
          smooth={true}
          offset={0}
          duration={700}
        >
          <div className="img">
            <img src={logo} alt="logo" />
          </div>
        </Link>
      </nav>
    </div>
  );
};

export default Navbar;
