"use client";
import React, { useState } from "react";
import pharmacy from "./images/pharmacy.jpg";
import "./About.css";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div className="about" id="about">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <img src={pharmacy} alt="john" />
        <div className="col-2">
          <h2>About</h2>
          <span className="line"></span>
          <p style={{ textAlign: "justify", fontSize: "1.5rem" }}>
            <span style={{ fontFamily: "aboutFont" }}>Oz HealthBridge</span> is
            your gateway to the Gulf region and Australia.
            <br /> At{" "}
            <span style={{ fontFamily: "aboutFont" }}>Oz HealthBridge</span> ,
            we are dedicated to facilitating new opportunities for medical
            business partners to establish a significant presence in the
            thriving markets of the Middle East and Australia.
          </p>

          {/* <button className="button">Explore More</button> */}
        </div>
      </motion.div>
    </div>
  );
};

export default About;
