import React from "react";
import "./404.css";
import { useState } from "react";
import { FaBars, FaEnvelopeOpenText, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../components/images/logo6.png";

const NotFound = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <div className="container text-center" id="error">
      <div className="header nav-bg">
        <nav className="navbar">
          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={30} style={{ color: "#ffffff" }} />
            ) : (
              <FaBars size={30} style={{ color: "#ffffff" }} />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                onClick={closeMenu}
                smooth={true}
                offset={0}
                duration={700}
              >
                Home
              </Link>
            </li>
          </ul>

          <Link to="/" className="logo" smooth={true} offset={0} duration={700}>
            <div className="img">
              <img src={logo} alt="logo" />
            </div>
          </Link>
        </nav>
      </div>
      <svg height="100" width="100">
        <polygon
          points="50,25 17,80 82,80"
          stroke-linejoin="round"
          style={{ fill: "none", stroke: "#ff8a00", strokeWidth: 8 }}
        />
        <text
          x="42"
          y="74"
          fill="#ff8a00"
          fontFamily="sans-serif"
          fontWeight="900"
          fontSize="42px"
        >
          !
        </text>
      </svg>
      <div class="row">
        <div class="col-md-12">
          <div class="main-icon text-warning">
            <span class="uxicon uxicon-alert"></span>
          </div>
          <h1>File not found (404 error)</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-md-push-3">
          <p class="lead">
            If you think what you're looking for should be here, please contact
            the site owner on{" "}
            <span
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaEnvelopeOpenText />
              <a href="mailto: info@ozhealthbridge.com.au">
                info@ozhealthbridge.com.au
              </a>
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
