"use client";
import React from "react";
import "./Demo.css";
import Form from "./Form";

const Demo = () => {
  return (
    <div className="demo" id="contact">
      <div className="container">
        <Form />
      </div>
    </div>
  );
};

export default Demo;
