import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Vision from "../components/Vision";
import Services from "../components/Services";
import Demo from "../components/Demo";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Vision />
      <Services />
      <Demo />
      <Footer />
    </div>
  );
};

export default Home;
