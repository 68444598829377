"use client";
import React, { useState } from "react";
import "./Vision.css";
import { GiClick } from "react-icons/gi";
import Modal from "./Modal";
import { motion } from "framer-motion";

const Vision = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const data = {
    vision: {
      title: "Company Vision",
      text: "Oz HealthBridge is founded on the belief that collaboration among healthcare professionals can deliver a complete cycle of care to patients worldwide.",
    },
    mission: {
      title: "Our Mission",
      text: "Our mission at Oz HealthBridge is to provide our partners with the means to establish new channels, particularly in the Gulf region and Australia. We equip healthcare practitioners with the latest tools and updates, empowering them to deliver the utmost care to their patients.",
    },
  };
  console.log(data.vision);
  const toggleOpen = (content) => {
    setIsOpen((prev) => !prev);
    setContent(content);
  };
  return (
    <div className="vision" id="vision">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <div className="col-1" onClick={() => toggleOpen(data.vision)}>
          <img src={require("./images/vision.png")} />
        </div>
        <div className="col-2" onClick={() => toggleOpen(data.mission)}>
          <img src={require("./images/mission.png")} />
        </div>
      </motion.div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} content={content} />
    </div>
  );
};

export default Vision;
